/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 900px) {
    .page-container{
        display: flex;
        flex-direction: column-reverse;
        justify-items: center;
        align-items: center;
        margin-bottom:50px;
    }
    .inputs{
        width: 80vw;
    }
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */