.gpsIcon {
	display: inline-block;
	animation: spinner 2s linear infinite;
}
@keyframes spinner {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

